import React from 'react';

const Skills = () => {
  return (
    <section id="skills">
      <h2>Skills</h2>
      <h3>Tech Skills</h3>
      <ul>
        <li>HTML</li>
        <li>CSS</li>
        <li>JavaScript(MERN Stack)</li>        
        <li>Python (Django, Flask & RestFramewrk)</li>
        <li>SQL(Posgre, MySQL)</li>
        <li>AWS Elastic Cloud Computing</li>
        <li>Linux Server and Desktop</li>
        <li>Git and GitHub</li>
        <li>cPanel</li>
      </ul>
    </section>
  );
}

export default Skills;
