import React from 'react';



const Projects = () => {
  return (
    <section id="projects">
    <h2>Projects</h2>

        <h3>FlagQuiz App</h3>
        Summary: Flag quiz app that users can play by matching a country name with a country flag  
        <ul>   
        <li> Role: Sole author </li>
        <li>Tech Stack: HTML, CSS, JavaScript, jQuery, Django, SQLite, Bootstrap, Font Awesome, Canva </li>
        <li> Deployment stack: AWS Console, Nginx, Gunicorn </li>
        <li>
          <a href="https://github.com/bhekidaweti/capstone" target="_blank" rel="noopener noreferrer">
          link to project
          </a>
        </li>
        </ul>

        <h3>Booking System </h3>
        Summary: This is a booking system web app for a guest house built with Django, HTML, CSS and JavaScript 
        <ul> 
        <li> Role: Sole author </li>
        <li> Tech Stack: Django, PostgreSQL, HTML, CSS and JavaScript </li>
        <a href="https://github.com/bhekidaweti/booking-project" target="_blank" rel="noopener noreferrer">
        link to project
          </a>
        </ul> 
        <h3>Online Shop</h3>
        Summary: This is a fully functional online shopping website with PayPal and FastPay payment integration.
        <ul> 
        <li>Role: Sole author </li>
        <li> Tools: Django, PosgreSQL, jQuery, Bootstrap, Canva, Font Awesome, HTML, CSS and JavaScript</li> 
        <li> Other: Paypal and FastPay payment integration</li>
        <a href="https://github.com/bhekidaweti/dee-shop" target="_blank" rel="noopener noreferrer">
        link to project
          </a>
        </ul> 
        <h3>Django React Car Dealership</h3>
        Summary: Django backend and React frontend project for a car dealership website.
        <ul> 
        <li>Role: Sole author </li>
        <li> Tools: Django, PosgreSQL, HTML, CSS and JavaScript(React framework)</li> 
        <a href="https://github.com/bhekidaweti/car-dealer-django-react" target="_blank" rel="noopener noreferrer">
        link to project
          </a>
        </ul>
    </section>
  );
}

export default Projects;
