import React from 'react';

const AboutMe = () => {
  return (
    <section id="about">
      <h2>About Me</h2>
      <p></p>
    </section>
  );
}

export default AboutMe;
